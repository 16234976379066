import React from 'react';
import styled from 'styled-components';
import { colors } from '../styles/utilities';

const Sidebar = props => (
  <aside className={props.className}>
    <h3>Did You Know?</h3>
    <p>80% of vision problems worldwide are avoidable or even curable.</p>
    <a 
      className="btn btn--purple ga-on-click-and-enter"
      href="https://www.lenscrafters.com/ScheduleExamView?catalogId=11651&clearExams=1&langId=-1&storeNumber=0295&storeId=10851&cid=Yext_PagesSchedule_LCOD0295"
      target="_blank"
      rel="noopener noreferrer"
      ga='[
        "event", "click", {
          "event_category": "appointment",
          "event_label": "appointment-sidebar"
        }
      ]'
    >See an Optometrist</a>
  </aside>
)

export default styled(Sidebar)`
  border: 1px solid ${colors.purpleDark};
  border-radius: 1px;
  padding: 30px 15px;
  a {
    color: #fff;
  }
  font-size: 1rem;
`;