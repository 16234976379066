import React from "react";
import { graphql } from "gatsby"
import styled from 'styled-components';
import { above } from '../styles/utilities';
import Layout from '../layouts/default';
import SEO from '../components/seo';
import Div from '../components/Div';
import Hero from '../components/Hero';
import Content from '../components/Content';
import Sidebar from '../components/Sidebar';
import Trust from '../components/Trust';
import Location from '../components/Location';
import Footer from '../components/Footer';

const DefaultTemplate = (props) => (

  <Layout className={props.className} menu={props.data.menu} meta={props.data.site.siteMetadata}>

    <SEO
      title={
        props.data.content.frontmatter.seo
        && props.data.content.frontmatter.seo.title
          ? props.data.content.frontmatter.seo.title
          : props.data.site.siteMetadata.title
      }
      description={
        props.data.content.frontmatter.seo
        && props.data.content.frontmatter.seo.description
          ? props.data.content.frontmatter.seo.description
          : props.data.site.siteMetadata.description
      }
    />

    <Hero
      className="hero--default"
      menu={props.data.menu}
      hero={props.data.content.frontmatter.hero}
      phoneNumber={props.data.site.siteMetadata.phoneNumber}
    />
    <Div className="container">
      <div className="row">
        <div className="col-lg-8 p-l-0">
          <Content className="content--main" content={props.data.content.html}></Content>
        </div>
        <div className="col-lg-4">
          <Sidebar className="sidebar" />
        </div>
      </div>
    </Div>
    <Trust images={props.data.trust} />
    <Location location={props.data.location}></Location>
    <Footer menu={props.data.footerMenu} phoneNumber={props.data.site.siteMetadata.phoneNumber}></Footer>
  </Layout>

)

export default styled(DefaultTemplate)`
  .content--main {
    padding: 50px 0;
  }
  .sidebar {
    margin: 50px 0;
  }
  .p-l-0 {
    ${above.md`
      padding-left: 0;
    `}
  }
`;

export const query = graphql`
  query($id: String!) {

    site {
      siteMetadata {
        phoneNumber
        title
      }
    }
    
    content: markdownRemark(id: {eq: $id}) {
      frontmatter {
        hero {
          image {
            childImageSharp {
              fluid(quality:100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
          image_alt
          heading
          text
          button_link
          button_text
          link_type
        }
        seo {
          title
          description
        }
      }
      html
    }

    location: markdownRemark(
      frontmatter: {
        name: {
          eq: "location"
        }
      }) {
      html
      frontmatter {
        button_link
        button_text
        button_type
        map
      }
    }

    menu: markdownRemark(
      frontmatter: {
        type: {
          eq: "menu"
        },
        name: {
          eq: "main-menu"
        }
      }) {
        html
        frontmatter {
          menu
        }
    }

    footerMenu: markdownRemark(
      frontmatter: {
        type: {
          eq: "menu"
        },
        name: {
          eq: "footer-menu"
        }
      }) {
        html
        frontmatter {
          menu
        }
    }

    trust: allFile(filter: {
      name: {
        in: [
          "american-optometric-association-logo",
          "florida-optometric-association",
          "lenscrafters-logo"
        ]
      }
    }) {
      edges {
        node {
          childImageSharp {
            fixed(quality: 100, width: 300) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }

  }
`;